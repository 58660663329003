import * as _stimulus2 from "@hotwired/stimulus";
var _stimulus = "default" in _stimulus2 ? _stimulus2.default : _stimulus2;
import * as _lightgallery2 from "lightgallery";
var _lightgallery = "default" in _lightgallery2 ? _lightgallery2.default : _lightgallery2;
var exports = {};
(function (e, t) {
  "object" < "u" ? exports = t(_stimulus, _lightgallery) : (e = typeof globalThis < "u" ? globalThis : e || self, e.StimulusLightbox = t(e.Stimulus, e.lightgallery));
})(exports, function (e, t) {
  "use strict";

  class i extends e.Controller {
    connect() {
      this.lightGallery = t(this.element, {
        ...this.defaultOptions,
        ...this.optionsValue
      });
    }
    disconnect() {
      this.lightGallery.destroy();
    }
    get defaultOptions() {
      return {};
    }
  }
  return i.values = {
    options: Object
  }, i;
});
export default exports;